import { render, staticRenderFns } from "./GestionTemplateTh.vue?vue&type=template&id=2b320f55&scoped=true&"
import script from "./GestionTemplateTh.vue?vue&type=script&lang=js&"
export * from "./GestionTemplateTh.vue?vue&type=script&lang=js&"
import style0 from "./GestionTemplateTh.vue?vue&type=style&index=0&id=2b320f55&lang=scss&scoped=true&"
import style1 from "./GestionTemplateTh.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b320f55",
  null
  
)

export default component.exports