<template
  ><div class="gestion-template-th">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="title">
          Gestion des templates
        </div>
        <div>
          <b-button
            variant="success"
            class="config-btn ml-2"
            @click.stop="initialisetinymce"
            ><font-awesome-icon icon="plus" /> Ajouter une template
          </b-button>
          <!-- Modal New Template  -->
          <Modal
            v-model="showTemlplate"
            modalClass="ModelClass"
            title="Ajouter une template"
            @after-close="resetModal"
          >
            <form @submit.prevent="handleSubmitTemplate">
              <b-row class="row mb-1">
                <!-- Title -->
                <b-col col xs="12" sm="3" md="3">
                  <b-form-group
                    label="Titre * "
                    label-for="title-template-input"
                    class=" input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="title-template-input"
                      v-model="templateToAdd.title"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Service -->
                <b-col col xs="12" sm="3" md="3">
                  <!-- Service  --><b-form-group
                    label="Service"
                    label-for="type-template-select"
                    class=" input-modal-champ "
                  >
                    <b-form-select
                      id="type-template-select"
                      v-model="templateToAdd.type"
                      :options="listServise"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis "
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Category -->
                <b-col col xs="12" sm="3" md="3">
                  <!-- Category  --><b-form-group
                    label="Catégorie"
                    label-for="category_id-template-select"
                    class=" input-modal-champ "
                  >
                    <b-form-select
                      id="category_id-template-select"
                      v-model="templateToAdd.category_id"
                      :options="computedCategorie"
                      text-field="name"
                      value-field="id"
                      class="b-form-select-raduis "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="templateToAdd.type === 'template'"
                  col
                  xs="12"
                  sm="3"
                  md="3"
                >
                  <!-- Category  -->
                  <b-form-group
                    label="Societe"
                    label-for="SCI-template-select"
                    class=" input-modal-champ "
                  >
                    <b-form-select
                      id="category_id-template-select"
                      v-model="templateToAdd.societe_id"
                      :options="getSettingFilialesTh"
                      text-field="name"
                      value-field="id"
                      class="b-form-select-raduis "
                    ></b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <!--b-row class="row mb-1"-->
              <!-- SWIFT && IBAN  -->
              <!--b-col col xs="12" sm="6" md="6">
                  <b-form-group
                    label="Iban *"
                    label-for="iban-template-input"
                    class=" input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="iban-template-input"
                      v-model="templateToAdd.iban"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col col xs="12" sm="6" md="6">
                  <b-form-group
                    label="Swift *"
                    label-for="swift-template-input"
                    class=" input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="swift-template-input"
                      v-model="templateToAdd.swift"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row-->
              <div class=" mb-1 ml-3">
                <!-- Description  -->
                <b-form-group
                  label="Description *"
                  label-for="title-template-description"
                  class=" input-modal-champ "
                >
                  <b-form-textarea
                    id="title-template-description"
                    v-model="templateToAdd.description"
                    required
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class=" mb-1 ml-3">
                <!-- Description 2 -->
                <b-form-group
                  label="Description 2"
                  label-for="title-template-description-2"
                  class=" input-modal-champ "
                >
                  <b-form-textarea
                    id="title-template-description-2"
                    v-model="templateToAdd.description2"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <b-row class="row mb-1">
                <!-- Fields -->
                <div class="mb-1 ml-3 titleFields">
                  Champs
                  <div class="CopieMsg" v-if="copierText">
                    Text copier
                  </div>
                </div>
                <b-row class="row mb-1 ml-3">
                  <div
                    v-for="(prop, val) of getTemplatesFields"
                    :key="prop.id"
                    @click="CopyFields({ prop: prop, val: val })"
                    class="filed-class"
                    title="Copier"
                  >
                    <!-- <div class="icon-field" title="Modifiable">
                          <b-form-checkbox
                            v-model="field.editable"
                            button-variant="succes"
                            :value="true"
                            :unchecked-value="false"
                          >
                          </b-form-checkbox>
                        </div> -->
                    <div class="icon-field">☰</div>
                    <div class="value-field">
                      {{ val }}
                    </div>
                    <div class="icon-copy">
                      <font-awesome-icon icon="copy" />
                    </div>
                  </div>
                </b-row>
              </b-row>
              <b-row class="row">
                <!-- Body -->
                <b-col col xs="12" sm="12" md="12">
                  <editor
                    v-model="templateToAdd.body"
                    :init="computedDataTinymce.init"
                  ></editor>
                </b-col>
              </b-row>
              <div class="actionModel text-aligne-center">
                <div class="error-message mb-2 mt-2">
                  <div v-if="getTemplateError" class="error ">
                    <ul v-if="Array.isArray(getTemplateError)" class="mb-0">
                      <li v-for="(e, index) in getTemplateError" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ getTemplateError }}</div>
                  </div>
                </div>
                <b-button type="submit" class="button-valide-style mb-2 mt-2">
                  <span>
                    Valider
                    <div v-if="getNewTemplateLoading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </form>
            <!-- </template>
            </Card> -->
          </Modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
    </div>
    <div v-if="getTemplateLoading" class=" chargement-message-calender ml-2">
      Chargement en cours ...
    </div>
    <div class="templates-block">
      <b-list-group class="custom-list-group mt-2 mr-4">
        <b-button
          variant="success"
          class="config-btn mb-2"
          v-b-modal.addCategorie
          ><font-awesome-icon icon="plus" /> Ajouter une catégorie
        </b-button>
        <b-modal
          id="addCategorie"
          ref="addCategorie"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter une Catégorie</div>
            <div class="iconClose" @click.prevent="hideModal('addCategorie')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitCategorie"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom de catégorie"
                  label-for="categrie-name"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="categrie-name"
                    v-model="categorieToAdd"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button type="submit" class="button-valide-style ">
                    <span>
                      Valider
                      <div v-if="loading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-list-group-item
          button
          v-for="categorie in getAllTemplate"
          :key="categorie.name"
          @click.prevent="handleClickTemplate(categorie)"
          class="list-groupe-item-groupe-template"
        >
          <div class="list-item">
            <div
              class="icon"
              v-if="selectedTemplate && selectedTemplate.name == categorie.name"
            >
              <font-awesome-icon icon="check" />
            </div>
            <div>
              {{ categorie.name }}
            </div>
          </div>
          <b-dropdown
            v-if="categorie && categorie.id != 0"
            class="drop-down-list-item"
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            text="..."
          >
            <b-dropdown-item @click.stop="handleUpdateCategorie(categorie)">
              <font-awesome-icon class="icon" icon="pencil-alt" />Renommer
            </b-dropdown-item>
            <b-dropdown-item @click.stop="handleDeleteCategorie(categorie)">
              <font-awesome-icon
                class="icon"
                icon="trash-alt"
              />Supprimer</b-dropdown-item
            >
          </b-dropdown>
        </b-list-group-item>
      </b-list-group>
      <div class="mt-2 template-block-style">
        <b-list-group
          v-if="selectedTemplate && selectedTemplate.templates.length > 0"
        >
          <b-list-group-item
            v-for="template in selectedTemplate.templates"
            :key="template.id"
            ><div class="content-template">
              <div class="title">
                {{ template.name }}
                <!-- ({{ template.type | filterType }}) -->
              </div>

              <div class="btn-action">
                <b-button
                  size="sm"
                  variant="secondary"
                  title="Visualiser"
                  @click.prevent.stop="handleShowClick(template)"
                >
                  <font-awesome-icon class icon="eye" />
                </b-button>
                <b-button
                  size="sm"
                  variant="primary"
                  title="Modifier"
                  @click.prevent.stop="handleUpdateClick(template)"
                >
                  <font-awesome-icon class icon="pencil-alt" />
                </b-button>
                <b-button
                  size="sm"
                  title="Supprimer"
                  variant="danger"
                  @click.prevent.stop="handleDeleteClick(template)"
                >
                  <font-awesome-icon class icon="trash-alt" />
                </b-button>
              </div></div
          ></b-list-group-item>
        </b-list-group>
      </div>
    </div>
    <!-- Delete Template Modal -->
    <b-modal
      no-close-on-backdrop
      ref="deleteTemplateModal"
      id="deleteTemplateModal"
      title=" Supprimer une Template "
      :hide-footer="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap "
    >
      <p>
        Êtes-vous certain de vouloir supprimer
        <strong> {{ TemplateToDelete ? TemplateToDelete.name : '' }}</strong>
        ?
      </p>
      <div class="message  text-aligne-center">
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button
            type="submit"
            class="button-valide-style mt-1"
            @click="supprimerTemplate"
          >
            <span>
              Valider
              <div v-if="gettemplateLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- End Delete Template Modal -->
    <!--show template -->
    <b-modal
      no-close-on-backdrop
      ref="showTemplateModal"
      id="showTemplateModal"
      :title="TemplateToShow && TemplateToShow.name ? TemplateToShow.name : ''"
      size="lg"
      :hide-footer="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap "
    >
      <div v-if="TemplateToShow" v-html="TemplateToShow.content"></div>
      <div class="message">
        <div v-if="getTemplateLoading" class="loading">
          Chargement en cours...
        </div>
      </div>
      <div class="form-actions mt-5 text-aligne-center">
        <b-button
          variant="outline-secondary"
          @click="hideModal('showTemplateModal')"
        >
          Fermer
        </b-button>
      </div>
    </b-modal>
    <!-- Update Template Modal   -->
    <Modal
      v-model="showTemplateToUpdate"
      modalClass="ModelClass"
      title="Modifier  une template"
      @after-close="resetModal"
    >
      <form
        v-if="TemplateToUpdate"
        ref="updateTemplateForm"
        @submit.prevent="handleUpdateTemplate"
      >
        <b-row class="row mb-1">
          <!-- Title -->
          <b-col col xs="12" sm="3" md="3">
            <b-form-group
              label="Titre * "
              label-for="title-template-input"
              class=" input-modal-champ "
            >
              <b-form-input
                autocomplete="off"
                id="title-template-input"
                v-model="TemplateToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- Fields -->
          <b-col col xs="12" sm="3" md="3">
            <!-- Service  --><b-form-group
              label="Service"
              label-for="type-template-select"
              class=" input-modal-champ "
            >
              <b-form-select
                id="type-template-select"
                v-model="TemplateToUpdate.type"
                :options="listServise"
                disabled
                text-field="text"
                value-field="value"
                class="b-form-select-raduis "
              ></b-form-select>
            </b-form-group>
          </b-col>
          <!-- Category -->
          <b-col col xs="12" sm="3" md="3">
            <!-- Category  --><b-form-group
              label="Catégorie"
              label-for="category_id-template-select"
              class=" input-modal-champ "
            >
              <b-form-select
                id="category_id-template-select"
                v-model="TemplateToUpdate.category_id"
                :options="computedCategorie"
                text-field="name"
                value-field="id"
                class="b-form-select-raduis "
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="TemplateToUpdate.type === 'template'"
            col
            xs="12"
            sm="3"
            md="3"
          >
            <!-- Category  --><b-form-group
              label="Societe"
              label-for="SCI_id-template-select"
              class=" input-modal-champ "
            >
              <b-form-select
                id="category_id-template-select"
                v-model="TemplateToUpdate.societe_id"
                :options="getSettingFilialesTh"
                text-field="name"
                value-field="id"
                class="b-form-select-raduis "
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <template>
          <div class="rib_block">
            <b-row
              class="mb-1 "
              v-for="(rib, index) in TemplateToUpdate.ribs"
              :key="index"
            >
              <!-- SWIFT && IBAN  -->
              <b-col col xs="12" sm="5" md="5">
                <b-form-group
                  label="Iban *"
                  label-for="iban-template-input"
                  class=" input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="iban-template-input"
                    v-model="rib.iban"
                    @keyup="ChangeIcon(index)"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col col xs="12" sm="5" md="5">
                <b-form-group
                  label="Swift *"
                  label-for="swift-template-input"
                  class=" input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="swift-template-input"
                    v-model="rib.swift"
                    @keyup="ChangeIcon(index)"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>

              <div class="edit-action">
                <div
                  class="action-icon validate"
                  @click="handleSubmitRib(index)"
                  v-if="rib.newLine == true"
                >
                  <font-awesome-icon icon="check" class="fa-2x bell1" />
                </div>

                <div
                  class="action-icon validate"
                  @click="handleUpdateRib(index)"
                  v-if="rib.changeSave == true && rib.newLine == false"
                >
                  <font-awesome-icon icon="check" class="fa-2x bell1" />
                </div>

                <div
                  class="action-icon "
                  @click="BlockUnblockRibClick(index)"
                  v-if="rib.blocked == true"
                >
                  <font-awesome-icon icon="eye-slash" class="fa-2x bell1" />
                </div>
                <div
                  class="action-icon "
                  @click="BlockUnblockRibClick(index)"
                  v-if="rib.blocked == false && rib.FoundRib != false"
                >
                  <font-awesome-icon icon="eye" class="fa-2x bell1" />
                </div>

                <div
                  class="action-icon cancel"
                  @click="DeleteRib(index)"
                  v-if="rib.newLine == true"
                >
                  <font-awesome-icon icon="times" class="fa-2x bell1" />
                </div>
              </div>
            </b-row>

            <div class="button-add-rib">
              <b-button
                variant="success"
                class="rib-add-btn  ml-2"
                @click="addLinetRib()"
                ><font-awesome-icon icon="plus" /> Ajouter un RIB</b-button
              >
              <div
                v-if="getLoadingRibTemplate"
                class=" chargement-message-calender ml-2"
              >
                Chargement en cours ...
              </div>
              <div class="error-message ml-2">
                <div v-if="getErrorRibTemplate" class="error ">
                  <ul v-if="Array.isArray(getErrorRibTemplate)" class="mb-0">
                    <li v-for="(e, index) in getErrorRibTemplate" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ getErrorRibTemplate }}</div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class=" mb-1 ml-3">
          <!-- Description  -->
          <b-form-group
            label="Description *"
            label-for="title-template-description"
            class=" input-modal-champ "
          >
            <b-form-textarea
              id="title-template-description"
              v-model="TemplateToUpdate.description"
              required
              rows="3"
            ></b-form-textarea>
          </b-form-group>
        </div>

        <div class=" mb-1 ml-3">
          <!-- Description 2   -->
          <b-form-group
            label="Description 2"
            label-for="title-template-description-2"
            class=" input-modal-champ "
          >
            <b-form-textarea
              id="title-template-description-2"
              v-model="TemplateToUpdate.description2"
              rows="3"
            ></b-form-textarea>
          </b-form-group>
        </div>
        <b-row class="row mb-1">
          <!-- Fields -->
          <div class="mb-1 ml-3 titleFields">
            Champs
            <div class="CopieMsg" v-if="copierText">
              Text copier
            </div>
          </div>
          <b-row class="row mb-1 ml-3">
            <div
              v-for="(prop, val) of getTemplatesFields"
              :key="prop.id"
              @click="CopyFields({ prop: prop, val: val })"
              class="filed-class"
              title="Copier"
            >
              <div class="icon-field">☰</div>
              <div class="value-field">
                {{ val }}
              </div>
              <div class="icon-copy">
                <font-awesome-icon icon="copy" />
              </div>
            </div>
          </b-row>
        </b-row>
        <b-row class="row">
          <!-- Body -->
          <b-col col xs="12" sm="12" md="12">
            <editor
              v-model="TemplateToUpdate.content"
              :init="computedDataTinymce.init"
            ></editor>
          </b-col>
        </b-row>
        <div class="actionModel text-aligne-center">
          <div class="error-message mb-2 mt-2">
            <div v-if="getTemplateError" class="error ">
              <ul v-if="Array.isArray(getTemplateError)" class="mb-0">
                <li v-for="(e, index) in getTemplateError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getTemplateError }}</div>
            </div>
          </div>
          <b-button type="submit" class="button-valide-style mb-2 mt-2">
            <span>
              Valider
              <div v-if="gettemplateLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </form>
    </Modal>
    <!--  Delete Categorie Modal -->
    <b-modal
      ref="CategorieDeleteModal"
      id="CategorieDeleteModal"
      no-close-on-backdrop
      :hide-footer="true"
      title="Supprimer une permission "
      @hidden="hideModal('CategorieDeleteModal')"
    >
      <p>
        Êtes-vous certain de vouloir supprimer la catégorie
        <strong class="name">
          {{ selectedCategorieToDelete ? selectedCategorieToDelete.name : '' }}
        </strong>
        ?
      </p>
      <div class="form-modal-custom-style">
        <div class="messageError">
          <div v-if="getTemplateError" class="error">
            <ul v-if="Array.isArray(getTemplateError)">
              <li v-for="(e, index) in getTemplateError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTemplateError }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click.prevent="handleModalSupprimerValider"
          >
            <span>
              Confirmer
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Delete Categorie Modal -->
    <!-- Update Name Categorie Modal -->
    <b-modal
      id="UpdateNameCategorieModal"
      ref="UpdateNameCategorieModal"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Renommer une catégorie</div>

        <div
          class="iconClose"
          @click.prevent="hideModal('UpdateNameCategorieModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitUpdateNameCategorie"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom"
              label-for="Nom"
              class="input-modal-champ"
              v-if="selectedCategorieToUpdate && selectedCategorieToUpdate.name"
            >
              <b-form-input
                autocomplete="off"
                id="Nom"
                v-model="selectedCategorieToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>

            <div class="messageError mt-3">
              <div v-if="getTemplateError" class="error">
                {{ getTemplateError }}
              </div>
            </div>
            <div class="actionModel">
              <b-button type="submit" class="button-valide-style ">
                <span
                  >Enregistrer
                  <div v-if="gettemplateLoading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END Update Name Modal-->
  </div>
</template>
<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import { mapActions, mapGetters } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import VueModal from '@kouts/vue-modal';
import '@kouts/vue-modal/dist/vue-modal.css';
export default {
  name: 'Gestion-des-templates',
  data() {
    return {
      templateToAdd: {
        title: null,
        subject: null,
        type: null,
        body: null,
        description: null,
        description2: null,
        category_id: null,
        swift: null,
        iban: null,
        societe_id: null
      },
      copierText: false,
      error: null,
      loading: false,
      listServise: [
        { value: 'NOVAE', text: 'NOVAE' },
        { value: 'CACTUS', text: 'CACTUS' },
        { value: 'CACTUS FRAIS', text: 'CACTUS FRAIS' },
        { value: 'WEB ABONDAN', text: 'WEB ABONDAN' },
        { value: 'ISO 33', text: 'KYNAN TECHNIQUE' },
        { value: 'RGE', text: 'RGE' },
        { value: 'DNH', text: 'LENA' },
        { value: 'SAV-S', text: 'SAV-S' },
        { value: 'WEB ABONDANCE', text: 'WEB ABONDANCE' },
        { value: 'CORPEDYS', text: 'CORPEDYS' },
        { value: 'ACTEO', text: 'ACTEO' },
        { value: 'GE COMPTA', text: 'GE COMPTA' },
        { value: 'GE RH', text: 'GE RH' },
        { value: 'KYNAN - ISO 33 - ISAUDIT', text: 'KYNANE' },
        { value: 'NOVAE MAIRIE', text: 'NOVAE MAIRIE' },
        { value: 'GSE', text: 'GSE' },
        { value: 'LAV', text: 'LAV' },
        { value: 'AFSP', text: 'AFSP' },
        { value: 'AFCONFIRMATION', text: 'AFCONFIRMATION' },
        { value: 'AFCOMMERCIAL', text: 'AFCOMMERCIAL' },
        { value: 'BE KYNAN', text: 'BE KYNAN' },
        { value: 'BE ISO33', text: 'BE ISO33' },
        { value: 'BE ISO AUDIT', text: 'BE ISO AUDIT' },
        { value: 'BE ENERGIE INVEST', text: 'Gestion Loyer EI - SCI' },
        { value: 'BE B ALTEO', text: 'BE B ALTEO' },
        { value: 'BE B KYNAN', text: 'BE B KYNAN' },
        { value: 'OBLIGE PRIME', text: 'OBLIGE PRIME' },
        { value: 'OBLIGE COMM', text: 'OBLIGE COMMISSION' },
        { value: 'OBLIGE SURPRIME', text: 'OBLIGE SURPRIME' },
        {
          value: 'SERVICE MEGA MAIL',
          text: 'SERVICE MEGA MAIL'
        },
        {
          value: 'OBLIGE COMMISION COMMERCIAL',
          text: 'OBLIGE COMMISION COMMERCIAL'
        },
        { value: 'OBLIGE PRIME PAR DEPOT', text: 'OBLIGE PRIME PAR DEPOT' },
        { value: 'OBLIGE COMM PAR DEPOT', text: 'OBLIGE COMMISSION PAR DEPOT' },
        {
          value: 'OBLIGE SURPRIME PAR DEPOT',
          text: 'OBLIGE SURPRIME PAR DEPOT'
        },
        {
          value: 'OBLIGE COMMISION COMMERCIAL AV',
          text: 'OBLIGE COMMISION COMMERCIAL AV'
        },
        {
          value: 'SERVICE MAIL',
          text: 'SERVICE MAIL'
        },
        { value: 'SCI', text: 'SCI' },
        { value: 'SCI ENERGIE INVEST', text: 'SCI ENERGIE INVEST' },
        {
          value: 'upcube',
          text: 'UPCUBE TECHNOLOGIES'
        },
        {
          value: 'IHS HABITAT',
          text: 'IHS HABITAT'
        },
        {
          value: 'RENOV & MON HABITAT',
          text: 'RENOV & MON HABITAT'
        },
        {
          value: 'EFFIMAX',
          text: 'EFFIMAX'
        },
        {
          value: 'CWF CONSULTING',
          text: 'CWF CONSULTING'
        },
        {
          value: 'alteo_control_gestion',
          text: 'Alteo contrôle qualité'
        },
        {
          value: 'template',
          text: 'Template'
        }
      ],
      showTemlplate: false,
      error: null,
      loading: false,
      TemplateToDelete: null,
      TemplateToUpdate: null,
      TemplateToShow: null,
      showTemplateToUpdate: false,
      selectedTemplate: null,
      categorieToAdd: null,
      selectedCategorieToUpdate: null,
      OldCategorie: null,
      selectedCategorieToDelete: null,
      TemplateToUpdateCategorie: null
    };
  },

  methods: {
    ...mapActions([
      'fetchTemplatesFields',
      'newTemplate',
      'resetErrorlTemplate',
      'setLisImage',
      'getAllImageTemplate',
      'resetErrorTemplate',
      'updateTemplate',
      'deleteTemplate',
      'fetchAllTemplates',
      'fetchTemplateLibre',
      'newCategorieTemplate',
      'updateNameCategorie',
      'deleteCategorie',
      'addRib',
      'blockunblockRib',
      'updateRib',
      'getAllSciTypeSci',
      'getSettingFilialeTh'
    ]),
    initialisetinymce() {
      this.showTemlplate = true;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    resetModal() {
      this.templateToAdd = {
        title: null,
        subject: null,
        type: null,
        body: null,
        description: null,
        description2: null,
        category_id: null,
        swift: null,
        iban: null,
        societe_id: null
      };
      this.TemplateToDelete = null;
      this.TemplateToShow = null;
      this.resetErrorTemplate();
      this.categorieToAdd = null;
      this.TemplateToUpdateCategorie = null;
    },
    async handleSubmitTemplate() {
      const body =
        '<!DOCTYPE html><html><head><meta charset="UTF-8"> <title>' +
        this.templateToAdd.title +
        '</title></head><body>' +
        this.templateToAdd.body +
        '</body></html>';
      const response = await this.newTemplate({
        template: {
          content: body,
          name: this.templateToAdd.title,
          type: this.templateToAdd.type,
          description: this.templateToAdd.description,
          description2: this.templateToAdd.description2,
          category_id: this.templateToAdd.category_id,
          swift: this.templateToAdd.swift,
          iban: this.templateToAdd.iban,
          societe_id: this.templateToAdd.societe_id
        }
      });
      if (response) {
        this.showTemlplate = false;
      }
    },
    CopyFields(payload) {
      var copyTextarea = document.createElement('textarea');
      copyTextarea.style.position = 'fixed';
      copyTextarea.style.opacity = '0';
      copyTextarea.textContent = payload.prop;
      copyTextarea.value = payload.prop;

      document.body.appendChild(copyTextarea);
      copyTextarea.select();
      document.execCommand('copy');
      document.body.removeChild(copyTextarea);
      this.copierText = true;
      setTimeout(() => {
        this.copierText = false;
      }, 800);
    },
    handleUpdateClick(template) {
      this.TemplateToUpdate = template;
      this.TemplateToUpdateCategorie = template.category_id;
      this.showTemplateToUpdate = true;
    },
    async handleUpdateTemplate() {
      const response = await this.updateTemplate({
        TemplateToUpdate: this.TemplateToUpdate,
        TemplateToUpdateCategorie: this.TemplateToUpdateCategorie
      });
      if (response.succes) {
        this.showTemplateToUpdate = false;
        this.resetModal();
      }
    },
    handleDeleteClick(template) {
      this.TemplateToDelete = template;
      this.$refs['deleteTemplateModal'].show();
    },
    async supprimerTemplate() {
      const response = await this.deleteTemplate({
        template: this.TemplateToDelete
      });
      if (response) {
        this.hideModal('deleteTemplateModal');
      }
    },
    handleShowClick(Template) {
      this.TemplateToShow = Template;
      this.$refs['showTemplateModal'].show();
    },
    handleClickTemplate(item) {
      this.selectedTemplate = item;
    },
    async hendleSubmitCategorie() {
      const response = await this.newCategorieTemplate({
        name: this.categorieToAdd
      });
      if (response) {
        this.hideModal('addCategorie');
      }
    },
    handleUpdateCategorie(categorie) {
      this.selectedCategorieToUpdate = { ...categorie };
      this.OldCategorie = categorie;
      this.$refs['UpdateNameCategorieModal'].show();
    },
    handleDeleteCategorie(categorie) {
      this.selectedCategorieToDelete = categorie;

      this.$refs['CategorieDeleteModal'].show();
    },
    async submitUpdateNameCategorie() {
      const response = await this.updateNameCategorie({
        categorie: this.selectedCategorieToUpdate,
        oldCategorie: this.OldCategorie
      });
      if (response) {
        this.hideModal('UpdateNameCategorieModal');
      }
    },
    async handleModalSupprimerValider() {
      const response = await this.deleteCategorie(
        this.selectedCategorieToDelete
      );
      if (response) {
        if (this.selectedCategorieToDelete.templates.length) {
          this.fetchTemplateLibre({ refresh: true });
          this.selectedTemplate = null;
        }
        if (
          this.selectedCategorie &&
          this.selectedCategorie.name &&
          this.selectedCategorie.name == this.selectedCategorieToDelete.name
        ) {
          this.selectedCategorie = null;
        }

        this.hideModal('CategorieDeleteModal');
      }
    },
    addLinetRib() {
      this.TemplateToUpdate.ribs.push({
        swift: null,
        iban: null,
        blocked: false,
        FoundRib: false,
        changeSave: false,
        newLine: true
      });
    },
    DeleteRib(index) {
      this.TemplateToUpdate.ribs.splice(index, 1);
    },
    async handleSubmitRib(index) {
      const response = await this.addRib({
        template_id: this.TemplateToUpdate.id,
        swift: this.TemplateToUpdate.ribs[index].swift,
        iban: this.TemplateToUpdate.ribs[index].iban,
        blocked: this.TemplateToUpdate.ribs[index].blocked,
        item: this.TemplateToUpdate,
        index: index
      });

      if (response == true) {
        this.TemplateToUpdate.ribs[index].FoundRib = true;
        this.TemplateToUpdate.ribs[index].changeSave = false;
        this.TemplateToUpdate.ribs[index].newLine = false;
      }
    },
    async handleUpdateRib(index) {
      const response = await this.updateRib({
        id: this.TemplateToUpdate.ribs[index].id,
        swift: this.TemplateToUpdate.ribs[index].swift,
        iban: this.TemplateToUpdate.ribs[index].iban,
        item: this.TemplateToUpdate,
        index: index
      });
      if (response == true) {
        this.TemplateToUpdate.ribs[index].FoundRib = true;
        this.TemplateToUpdate.ribs[index].changeSave = false;
      }
    },
    async BlockUnblockRibClick(index) {
      const response = await this.blockunblockRib({
        id: this.TemplateToUpdate.ribs[index].id,
        blocked: this.TemplateToUpdate.ribs[index].blocked ? false : true,
        item: this.TemplateToUpdate,
        index: index
      });

      if (response == true) {
      }
    },
    async ChangeIcon(index) {
      this.TemplateToUpdate.ribs[index].changeSave = true;
    }
  },
  components: {
    Card: () => import('../component/card.vue'),
    Editor,
    Modal: VueModal
  },
  computed: {
    ...mapGetters([
      'getNewTemplateLoading',
      'getImageTemplateList',
      'getTemplatesFields',
      'getTemplateError',
      'getAllTemplate',
      'getTemplateLoading',
      'gettemplateLoading',
      'getLoadingRibTemplate',
      'getErrorRibTemplate',
      'getTemplateRibFound',
      'getSciTypeSci',
      'getSettingFilialesTh'
    ]),
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url };
      });
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            );
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData();
            param.append('image', blobInfo.blob());
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url);
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message);
              });
          }
        }
      };
    },
    computedCategorie() {
      let tab = this.getAllTemplate.map(item => {
        return { id: item.id, name: item.name };
      });
      return tab;
    }
  },
  filters: {
    filterType: value => {
      if (value == 'DNH') {
        return 'LENA';
      }
      return value;
    }
  },
  async mounted() {
    this.fetchTemplatesFields();
    this.getAllImageTemplate();
    await this.fetchAllTemplates();
    this.fetchTemplateLibre();
    await this.getAllSciTypeSci();
    await this.getSettingFilialeTh();
  }
};
</script>

<style lang="scss" scoped>
.gestion-template-th {
  .list-group {
    .list-group-item {
      .content-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          width: 300px;
          font-size: 14px;
          font-weight: 800;
          font-family: 'Montserrat', sans-serif;
          color: #2a2a2a;
        }
        .btn-action {
          display: block;
          margin-right: 20px;
          white-space: nowrap;
          button {
            margin: 0 2px;
          }
        }
      }
    }
  }
  .template-block-style {
    height: calc(100vh - 165px);
    display: block;
    overflow-y: auto;
    width: 78%;
  }
  .templates-block {
    display: flex;
    justify-content: space-between;
    .list-groupe-item-groupe-template {
      width: 200px;
    }
  }
}
.rib_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;
  .button-add-rib {
    display: flex;
  }
  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;
    .action-icon {
      cursor: pointer;
    }
    .validate {
      color: green;
    }
    .cancel {
      color: #ab2525;
    }
  }
  .line-rib {
    display: flex;
    justify-content: space-between;
  }
  .rib-add-btn {
    font-size: 9px;
    min-width: 70px;
    text-align: center;
    background-color: #7a78dc;
    border-radius: 3px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    padding: 5px;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.titleFields {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
  .CopieMsg {
    margin-left: 50px;
    color: #4d4bac;
    font-weight: 12px;
  }
}
.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 211px;
  margin: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;

  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .value-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 25px;
  }
}
.width-custom-modal {
  .modal-dialog {
    max-width: 530px;
  }
}
.tox-notifications-container {
  display: none !important;
}
.ModelClass {
  border-radius: 5px;
  max-width: 800px;
  width: auto;
  .vm-title {
    font-size: 1.25rem;
  }
}
.template-block-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.template-block-style::-webkit-scrollbar-track,
template-style {
  box-shadow: inset 0 0 5px #eae7f6;
  border-radius: 10px;
}
.template-block-style::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
</style>
